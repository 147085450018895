var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    [_vm.QC_STATUS_ENUM.hasDo.value, _vm.QC_STATUS_ENUM.return.value].includes(
      _vm.data.qcStatus
    )
      ? _c(
          "div",
          {
            class: [
              "tag",
              Object.values(_vm.QC_STATUS_ENUM).find(
                (item) => item.value === _vm.data.qcStatus
              ).key,
            ],
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  Object.values(_vm.QC_STATUS_ENUM).find(
                    (item) => item.value === _vm.data.qcStatus
                  ).label
                ) +
                " "
            ),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "name", attrs: { title: _vm.data.storeDeliveryName } },
      [
        _vm._v(
          " " + _vm._s(`${_vm.index + 1}、${_vm.data.storeDeliveryName}`) + " "
        ),
      ]
    ),
    _c("div", { staticClass: "sku" }, [
      _vm._v(" " + _vm._s(`SKU数量：${_vm.data.skuQuantity}`) + " "),
    ]),
    _c("div", { staticClass: "total" }, [
      _c("div", { staticClass: "count" }, [
        _vm._v(" 总计 "),
        _c("span", [_vm._v(_vm._s(_vm.data.originNumber || "-"))]),
      ]),
      _c("div", { staticClass: "lose" }, [
        _vm._v(" 缺货 "),
        _c("span", [_vm._v(_vm._s(_vm.data.outStockNumber))]),
      ]),
      _c("div", { staticClass: "next" }, [
        _vm._v(" 甩货数量 "),
        _c("span", [_vm._v(_vm._s(_vm.data.throwNumber))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }