<template>
  <section>
    <!-- 卡片标签 start -->
    <!-- 只有已复核、整单退回展示 tag -->
    <div
      :class="[
        'tag',
        Object.values(QC_STATUS_ENUM).find(
          (item) => item.value === data.qcStatus
        ).key,
      ]"
      v-if="
        [QC_STATUS_ENUM.hasDo.value, QC_STATUS_ENUM.return.value].includes(
          data.qcStatus
        )
      "
    >
      {{
        Object.values(QC_STATUS_ENUM).find(
          (item) => item.value === data.qcStatus
        ).label
      }}
    </div>
    <!-- 卡片标签 end -->
    <!-- 卡片信息 start -->
    <div class="name" :title="data.storeDeliveryName">
      {{ `${index + 1}、${data.storeDeliveryName}` }}
    </div>
    <!-- <div class="level">
      <div>{{ `等级：${data.productGrade}` }}</div>
      <div>{{ `货号：${data.productSkuCode}` }}</div>
    </div>
    <div class="suffix" :title="data.productSpec">
      {{ `规格：${data.productSpec}` }}
    </div> -->
    <div class="sku">
      {{ `SKU数量：${data.skuQuantity}` }}
    </div>
    <div class="total">
      <div class="count">
        总计 <span>{{ data.originNumber || "-" }}</span>
      </div>
      <div class="lose">
        缺货 <span>{{ data.outStockNumber }}</span>
      </div>
      <div class="next">
        甩货数量 <span>{{ data.throwNumber }}</span>
      </div>
    </div>
    <!-- 卡片信息 end -->
  </section>
</template>

<script>
import { QC_STATUS_ENUM } from "@/views/logistics/utils/enum";

export default {
  name: "Category",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: Number,
  },
  data() {
    return {
      QC_STATUS_ENUM,
    };
  },
};
</script>

<style lang="scss" scoped>
.tag {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0px 4px;
  font-size: 12px;
  border-radius: 2px;
  color: #fff;
  background-color: #fa884c;
  &.return {
    background-color: #ff090d;
  }
}
.name {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  color: $font-color;
}
.level,
.suffix {
  font-size: 12px !important;
  & > div {
    font-size: 12px !important;
  }
}
.sku {
  padding: 8px 0;
}
.name,
.suffix {
  @include ellipsis-lines;
}
.level {
  display: flex;
  gap: 35px;
  line-height: 24px;
}
.total {
  display: flex;
  line-height: 26px;
  & > div {
    flex: 1;
    font-size: 12px !important;
    & > span {
      position: relative;
      top: 1px;
    }
  }
  .count > span {
    font-size: 16px;
    font-weight: 600;
    color: rgba(#000000, 0.65);
  }
  .lose > span {
    font-size: 16px;
    font-weight: 600;
    color: #f85e28;
  }
  .next > span {
    font-size: 16px;
    font-weight: 600;
    color: #2371df;
  }
}
</style>
