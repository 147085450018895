<template>
  <section class="modules-wrap">
    <!-- 左侧数据，滚动区域 start -->
    <div class="list" @scroll="handScroll">
      <!-- 暴露每一个单元 -->
      <slot name="listBox"></slot>
    </div>
    <!-- 左侧数据，滚动区域 end -->
    <!-- 右侧锚点 start -->
    <div class="scroll" v-if="tableList.length > diff">
      <div class="step" v-for="step of maxStep" :key="step">
        <div
          class="step-one step-dit"
          :class="{ active: maxIndex === (step - 1) * diff }"
          @click="onClickHandle(step)"
        >
          {{ (step - 1) * diff || 1 }}#
        </div>
        <div
          class="step-two"
          v-for="dit of stepDitNum - 2"
          :key="dit"
          v-show="isStepShow(step, dit)"
          @click="onClickHandle(step, dit)"
          :class="{ active: maxIndex === (step - 1) * diff + dit * stepDitNum }"
        >
          <div class="dit step-dit"></div>
        </div>
      </div>
    </div>
    <!-- 右侧锚点 end -->
  </section>
</template>

<script>
export default {
  name: "AxlePagination",
  props: {
    /** 渲染的列表数据项 */
    tableList: {
      type: Array,
      default: () => [],
    },
    /** 每个单元的class名，需计算高度 */
    unitClass: {
      type: String,
      default: "box",
    },
    /** 视图内展示的元素个数 */
    diff: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      // 当前滚动的下标值
      stepIndex: 1,
      // 右侧数字间隔的锚点数
      stepDitNum: 5,
    };
  },
  computed: {
    /**
     * 计算出多少个主节点
     */
    maxStep() {
      let res = Math.floor(this.tableList?.length / this.diff) + 1;
      return res;
    },
    /**
     * 判断展示多少个点
     */
    isStepShow() {
      return (step, dit) => {
        // 计算展示几个点
        let res =
          this.tableList?.length >
          this.diff * (step - 1) + this.stepDitNum * (dit - 1);
        return res;
      };
    },
    /**
     * 计算当前需要高亮的锚点
     */
    maxIndex() {
      const max = this.stepIndex + this.diff - this.stepDitNum;
      // // console.log('🆒 stepIndex', max);
      return max < this.diff ? this.diff : max;
      // return this.stepIndex - this.stepDitNum;
    },
  },
  mounted() {},
  methods: {
    /**
     * 滚动事件监听
     */
    handScroll(e) {
      let scrollItems = document.querySelectorAll(`.${this.unitClass}`);
      // console.log("🆒 handScroll", e, scrollItems);
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          // console.log('🆒 handScroll i', i);
          this.stepIndex = i + 1;
          break;
        }
      }
    },
    /**
     * 点击右侧数轴，定位
     */
    onClickHandle(step, dit) {
      let pre = ((step && step - 1) || 0) * this.diff;
      let nex = (dit && dit * this.stepDitNum) || 0;
      this.stepIndex = pre + nex;
      // console.log("🆒 onClickHandle", pre, nex, this.stepIndex);
      let index =
        this.stepIndex - this.diff < this.diff ? 0 : this.stepIndex - this.diff;
      // console.log("🆒 onClickHandle", this.stepIndex, index);
      document.querySelectorAll(`.${this.unitClass}`)[index]?.scrollIntoView({
        // 平滑过渡
        behavior: "smooth",
        // 上边框与视窗顶部平齐
        block: "start",
      });
      this.$emit("emitScrollChange", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.modules-wrap {
  // background: #f0f2f5;
  // padding: 0 20px;
  // height: 580px;
  height: 100%;
  display: flex;
  position: relative;
  .list {
    flex: 1;
    display: flex;
    align-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    // overflow-y: hidden;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .scroll {
    padding: 10px 0;
    // margin-right: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    text-align: center;
    background-color: #f8faf8;
    .step {
      flex: 1;
      width: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $theme-color;
      cursor: pointer;
      &-one,
      &-two {
        flex: 1;
        font-size: 18px;
        font-weight: 400;
        .dit {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #9ad2b8;
        }
      }
      .active {
        font-size: 20px;
        font-weight: 600;
        // color: $theme-color;
        .step-dit {
          font-weight: 600;
          background: $theme-color;
        }
      }
    }
  }
}
</style>
