<template>
  <section class="page-wrap">
    <!-- 输入框 start -->
    <div class="input-wrap">
      <el-input
        v-model="sheetOrder"
        placeholder="请在此输入或扫描供货单编码"
        ref="refSheetOrder"
        autofocus="true"
        @change="changeSheetOrder"
        @keyup.enter.native="onHandleSearch"
      >
        <el-button
          slot="suffix"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="onHandleSearch"
          >确定</el-button
        >
      </el-input>
    </div>
    <!-- 输入框 end -->
    <!-- 复核单详情 start -->
    <div v-if="isShowInfo" v-loading="loading">
      <div class="sheet-info">
        <div class="sheet-info__header">
          供货单号：{{ formData.supplyOrderNo }}
        </div>
        <div class="sheet-info__body">
          <div class="info-one">供货日期：{{ formData.supplyDate }}</div>
          <div class="info-two">
            <!-- <div>SKU数量：{{ formData.skuNumber }}</div>
            <div>总件数：{{ formData.originNumber }}件</div>
            <div>总缺货：{{ formData.outStockNumber }}件</div>
            <div>总甩货：{{ formData.throwNumber }}件</div> -->
            <!-- 复核单三期，需求 -->
            <el-select
              placeholder="选择城市仓"
              v-model="form.logisticsId"
              @change="onChangeCityStore"
              :filter-method="(val) => onFilterMethod('cityStore', val)"
              @visible-change="(val) => onChangeVisible('cityStore', val)"
              filterable
              clearable
            >
              <el-option
                v-for="item in cityStoreList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              placeholder="选择自提点"
              v-model="form.deliveryId"
              @change="onChangeSelfTake"
              :loading="selfTakeListLoading"
              :filter-method="(val) => onFilterMethod('selfTake', val)"
              @visible-change="(val) => onChangeVisible('selfTake', val)"
              filterable
              clearable
            >
              <el-option
                v-for="item in selfTakeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 复核单详情 end -->
      <!-- 复核单列表 start -->
      <div class="list-wrap">
        <axle-pagination
          :tableList="tableData"
          :unitClass="unitClass"
          v-if="this.tableData.length"
        >
          <!-- 品类卡片信息 start -->
          <div
            slot="listBox"
            v-for="(item, index) of tableData"
            :key="index"
            :class="[
              unitClass,
              Object.values(QC_STATUS_ENUM).find(
                (data) => data.value === item.qcStatus
              ).key,
              // Object.values(QC_HANDLE_ENUM).find(
              //   (data) => data.value === item.isHandle
              // ).key,
              { readonly: isReadonly },
            ]"
            @click="onHandleReview(item)"
          >
            <category :index="index" :data="item"></category>
          </div>
          <!-- 品类卡片信息 end -->
        </axle-pagination>
        <div v-else>
          <c-empty-page tip="暂无数据~"></c-empty-page>
        </div>
      </div>
      <!-- 复核单列表 end -->
      <!-- 顶部与操作按钮 start -->
      <div class="footer" v-if="!isReadonly || (isReadonly && revocationTime)">
        <div class="handle" v-if="!isReadonly">
          <div class="footer-btn">
            <!-- <el-button type="warning" size="mini" @click="onHandleLose"
              >整单缺货</el-button
            > -->
            <el-button type="primary" size="mini" @click="onHandleSubmit"
              >复核确认</el-button
            >
          </div>
          <div class="footer-tip">
            点击复核完成将会给缺货的商品操作退款，请谨慎操作
          </div>
        </div>
        <div class="handle" v-if="isReadonly && revocationTime">
          <div class="footer-btn">
            <el-button type="info" size="mini" @click="onHandleRevocation">{{
              `撤销 ${revocationTime}`
            }}</el-button>
          </div>
        </div>
      </div>
      <!-- 顶部与操作按钮 end -->
    </div>

    <!-- 缺省 start -->
    <c-empty-page
      v-if="!isShowInfo"
      tip="请扫描或输入供货单编号~"
    ></c-empty-page>
    <!-- 缺省 end -->

    <!-- 单个品类确认缺货弹窗 start -->
    <confirm-count-dialog
      ref="refConfirmCount"
      @on-change-single="(data) => onHandleSearch(data)"
      @on-show-reason="onShowReason"
    ></confirm-count-dialog>
    <!-- 单个品类确认缺货弹窗 end -->

    <!-- 整单复核确认弹窗 start -->
    <submit-all-dialog
      ref="refSubmitAll"
      @on-submit-count="onHandleAllReview"
    ></submit-all-dialog>
    <!-- 整单复核确认弹窗 end -->

    <!-- 品类缺货原因 start -->
    <!-- <out-stock-reason-dialog ref="refOutStockReason" @on-edit-reason="onEditReason"></out-stock-reason-dialog> -->
    <!-- 品类缺货原因 end -->
  </section>
</template>

<script>
import AxlePagination from "./components/AxlePagination";
import Category from "./components/Category";
import ConfirmCountDialog from "./modules/confirm-count-dialog";
import SubmitAllDialog from "./modules/submit-all-dialog";
import OutStockReasonDialog from "./modules/out-stock-reason-dialog";
import CEmptyPage from "@/components/common/CEmptyPage";
import { QC_STATUS_ENUM, QC_HANDLE_ENUM } from "@/views/logistics/utils/enum";
// import mock from "./mock";
import debounce from "lodash/debounce";
import tool from "@/utils/tools/tool";
import {
  getGoodsSheetReviewInfo,
  // getGoodsSheetReviewList,
  postGoodsSheetRevocation,
  postGoodsSheetAllReview,
  postGoodsSheetAllLose,
  getGoodsSheetOutNumber,
} from "@/api/sheet-to-review/index";

export default {
  name: "SheetToReview",
  components: {
    AxlePagination,
    Category,
    ConfirmCountDialog,
    SubmitAllDialog,
    CEmptyPage,
    OutStockReasonDialog,
  },
  data() {
    return {
      // SOG20231013123133CDE4OPWSFHM
      loading: false,
      // 供货单编号
      sheetOrder: "",
      sheetOrderClone: "",
      form: {
        logisticsId: "",
        deliveryId: "",
      },
      // 城市仓列表
      cityStoreList: [],
      // 自提点列表
      selfTakeList: [],
      selfTakeListLoading: true,
      // 供货单详情
      formData: {
        supplyOrderNo: "",
        supplyDate: "",
        skuNumber: "",
        originNumber: "",
        outStockNumber: "",
        throwNumber: "",
        supplyOrderId: "",
      },
      // 城市仓名称
      // cityStoreName: "",
      // 自提点名称
      // selfTakeName: "",
      tableData: [],
      // 是否展示打印详情
      isShowInfo: false,
      unitClass: "box",
      // 复核状态枚举
      QC_STATUS_ENUM,
      // 是否可重新复核枚举
      QC_HANDLE_ENUM,
      // 界面是否只读
      isReadonly: false,
      // 是否隐藏确认按钮
      isHiddenSubmit: false,
      // 可撤回倒计时
      revocationTime: 0,
      // 计时器对象
      countDown: null,
    };
  },
  watch: {
    isShowInfo(val) {
      if (!val) {
        clearInterval(this.countDown);
      }
    },
  },
  computed: {},
  mounted() {
    // this.onHandleSearch();
    this.getAjaxCityStoreList();
    this.getAjaxSelfTakeList({});
    this.$refs.refSheetOrder.focus();
  },
  destroyed() {
    clearInterval(this.countDown);
  },
  methods: {
    /**
     * 供货单号发生改变
     */
    changeSheetOrder(data) {
      this.sheetOrderClone = data;
      // console.log("🆒 changeSheetOrder", data);
    },
    /**
     * 获取城市仓列表
     */
    getAjaxCityStoreList: debounce(async function (data) {
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckLogisticslist(params);
        this.cityStoreList = data.data;
        console.log("ajx getAjaxCityStoreList", data.data);
      } catch (err) {
        console.log("ajx getAjaxCityStoreList err", err);
      }
    }, 300),
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: debounce(async function ({
      name = "",
      logisticsId = "",
    }) {
      this.selfTakeListLoading = true;
      /**
       * 城市仓为必传参数
       * 防止接口在删除城市仓前请求数据，故先判断条件
       */
      // if (!logisticsId || !this.form.logisticsId) {
      //   return;
      // }
      const params = {
        name,
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
        from: "qc",
      };
      try {
        const { data } = await this.$api.logistics.entruckDeliverylist(params);
        this.selfTakeList = data.data;
        this.selfTakeListLoading = false;
        console.log("ajx getAjaxSelfTakeList", data.data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      }
    },
    300),
    /**
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      // console.log("🆒 onChangeCityStore 666666666", data, this.cityStoreList);
      // 重置自提点
      this.selfTakeList = [];
      this.form.deliveryId = "";
      // 获取城市仓名称
      // this.cityStoreName = this.cityStoreList.find(
      //   (item) => item.id === data
      // )?.name;
      // if (data) {
      const params = {
        name: "",
        logisticsId: data,
      };
      this.getAjaxSelfTakeList(params);
      // }
      this.onHandleSearch();
    },
    /**
     * 自提点发生变化
     */
    onChangeSelfTake(data) {
      // 获取字体点名称
      // this.selfTakeName = this.selfTakeList.find(
      //   (item) => item.id === data
      // )?.name;
      // console.log("onChangeSelfTake data", data);
      this.onHandleSearch();
    },
    /**
     * 搜索数据项
     */
    onFilterMethod(type, data) {
      if (type === "cityStore") {
        this.getAjaxCityStoreList(data);
      }
      if (type === "selfTake") {
        const params = {
          name: data,
          logisticsId: this.form.logisticsId,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 失焦时，初始化下拉框
     */
    onChangeVisible(type, data) {
      if (!data && type === "cityStore") {
        this.getAjaxCityStoreList();
      }
      if (!data && type === "selfTake") {
        const params = {
          name: "",
          logisticsId: this.form.logisticsId,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 查询供货单相关
     */
    async onHandleSearch(code) {
      // 清除残留的倒计时
      clearInterval(this.countDown);
      const qcCode = this.sheetOrderClone || (typeof code === "string" && code);
      if (!qcCode) {
        this.$message.error("请扫描或输入供货单编号~");
        return;
      }
      this.loading = true;
      const { logisticsId: storeLogisticsId, deliveryId: storeDeliveryId } =
        this.form;
      console.log("onHandleSearch 🆒 this.form", this.form);
      try {
        const params = {
          qcCode,
          storeLogisticsId,
          storeDeliveryId,
        };
        const { data } = await getGoodsSheetReviewInfo(params);
        const { qcInfo: info, aggregationQcList: list } = data;
        // console.log("ajax getGoodsSheetReviewInfo", info, list);
        // const { data: list } = getGoodsSheetReviewList();
        this.sheetOrder = "";
        this.tableData = list;
        // 模拟
        // info.isReadonly = true;
        // info.revocationTime = 285;
        this.formData = info;
        // this.isHiddenSubmit = info.confirmButton ? false : true;
        this.formData.revocationTime =
          parseInt(this.formData.revocationTime) * 1000;
        this.isShowInfo = true;
        const { isReadonly, revocationTime } = this.formData;
        // 如果存在倒计时，则开启
        this.isReadonly = isReadonly ? true : false;
        if (isReadonly && revocationTime) {
          this.setTimeCount();
        }
        console.log("ajax getGoodsSheetReviewInfo", info, list);
      } catch (err) {
        console.log("ajax getGoodsSheetReviewInfo err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 设置计时器
     */
    setTimeCount() {
      // 首次转换成 分-秒
      this.revocationTime = tool.setCountDown(this.formData.revocationTime);
      // 每隔多少毫秒执行一次
      let step = 970;
      let outTime = new Date().getTime();
      this.countDown = setInterval(() => {
        // 实时得出时间差
        let inTime = new Date().getTime();
        let diff = inTime - outTime;
        // console.log("🆒 setTimeCount diff", diff, this.revocationTime);
        let res = tool.setCountDown(this.formData.revocationTime - diff);
        if (!res) {
          clearInterval(this.countDown);
        }
        this.revocationTime = res;
      }, step);
    },
    /**
     * 打开缺货原因弹窗
     */
    onShowReason() {
      this.$refs.refOutStockReason.onInitData();
    },
    /**
     * 提交了缺货原因
     */
    onEditReason() {
      this.$refs.refConfirmCount.onInitData();
    },
    /**
     * 单个品类登记缺货
     */
    onHandleReview(row) {
      /**
       * 此逻辑废弃
       * 两种情况可触发缺货登记
       * 1：非撤回复核条件下，仅待复核状态
       * 2：撤回复核条件下，除了整单退回的其他状态
       */
      // const case1 =
      //   row.isHandle === QC_HANDLE_ENUM.unHandle.value &&
      //   row.qcStatus === QC_STATUS_ENUM.todo.value;
      // const case2 =
      //   row.isHandle === QC_HANDLE_ENUM.isHandle.value &&
      //   row.qcStatus !== QC_STATUS_ENUM.return.value;
      // if (case1 || case2) {
      // }
      /**
       * 非只读状态
       * 待复核、已确认缺货数，两种状态，可触发缺货登记
       */
      const handle = [
        QC_STATUS_ENUM.todo.value,
        QC_STATUS_ENUM.has.value,
      ].includes(row.qcStatus);
      if (!this.isReadonly && handle) {
        // console.log("🆒 onHandleReview row", row);
        const params = {
          ...row,
          supplyOrderNo: this.formData.supplyOrderNo,
          supplyOrderId: this.formData.supplyOrderId,
          // cityStoreName: this.cityStoreName,
          // selfTakeName: this.selfTakeName,
        };
        this.$refs.refConfirmCount.initShow(params);
      }
    },
    /**
     * 整单缺货（整单缺货按钮隐藏，已废弃）
     */
    onHandleLose() {
      this.$confirm("点击整单缺货将复核的全部退款，是否确认？", "提示", {
        customClass: "jn-confirm-message-style button-other-style",
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        iconClass: "el-icon-warning",
      })
        .then(() => {
          this.postAjaxGoodsSheetAllLose();
        })
        .catch(() => {});
    },
    /**
     * 整单缺货，提交（整单缺货按钮隐藏，已废弃）
     */
    async postAjaxGoodsSheetAllLose() {
      try {
        const params = {
          supplyOrderNo: this.formData.supplyOrderNo,
        };
        await postGoodsSheetAllLose(params);
        this.$message.success("操作成功~");
        this.isShowInfo = false;
      } catch (err) {
        console.log("ajax postGoodsSheetAllLose err", err);
      }
    },
    /**
     * 复核确认
     * 1. 如果缺货，二次确认数量
     * 2. 如果无缺货，则提示语确认
     */
    async onHandleSubmit() {
      // let lose = Number(this.formData.outStockNumber);
      let outList = [];
      // 查询当前总缺货数
      const paramsOutNumber = {
        supplyOrderId: this.formData.supplyOrderId,
      };
      try {
        const { data } = await getGoodsSheetOutNumber(paramsOutNumber);
        outList = data;
        console.log("ajax getGoodsSheetOutNumber", data);
      } catch (err) {
        console.log("ajax getGoodsSheetOutNumber err", err);
      }
      if (outList?.length) {
        /**
         * 将所有品类缺货数统计
         * 包含已确认的缺货数、整单退回两种状态
         */
        // const list = this.tableData.filter(
        //   (item) =>
        //     [QC_STATUS_ENUM.has.value, QC_STATUS_ENUM.return.value].includes(
        //       item.qcStatus
        //     ) && Number(item.outStockNumber)
        // );
        // console.log("🆒 onHandleSubmit", list, this.tableData);
        const params = {
          list: outList,
          supplyOrderNo: this.formData.supplyOrderNo,
        };
        // console.log("🆒 onHandleSubmit", params);
        this.$refs.refSubmitAll.initShow(params);
      } else {
        this.$confirm("此供货单全部复核完成，无缺货商品，是否继续？", "提示", {
          customClass: "jn-confirm-message-style",
          confirmButtonText: "确 定",
          cancelButtonText: "取 消",
          iconClass: "el-icon-warning",
        })
          .then(() => {
            this.postAjaxGoodsSheetAllReview();
          })
          .catch(() => {});
      }
    },
    /**
     * 整个供货单，不缺货复核
     */
    async postAjaxGoodsSheetAllReview(type = "un-lose", data) {
      const supplyOrderNo = this.formData.supplyOrderNo || data;
      if (!supplyOrderNo) {
        return;
      }
      const params = { supplyOrderNo };
      try {
        await postGoodsSheetAllReview(params);
        this.$message.success("操作成功~");
        // 有缺货，关闭二次确认列表
        if (type === "has-lose") {
          this.$refs?.refSubmitAll?.onHandleClose();
        }
        this.isShowInfo = false;
        // 重置供货单号，激活光标
        this.sheetOrderClone = "";
        this.$refs.refSheetOrder.focus();
      } catch (err) {
        console.log("ajax postGoodsSheetAllReview err", err);
      }
    },
    /**
     * 整个供货单，缺货复核
     */
    async onHandleAllReview(data) {
      this.postAjaxGoodsSheetAllReview("has-lose", data);
    },
    /**
     * 撤回复核
     */
    async onHandleRevocation() {
      try {
        const params = {
          supplyOrderNo: this.formData.supplyOrderNo,
        };
        await postGoodsSheetRevocation(params);
        // 刷新界面，和恢复初始状态
        this.$message.success("撤回成功，已为您初始化~");
        this.onHandleSearch(this.formData.supplyOrderNo);
      } catch (err) {
        console.log("ajax postGoodsSheetRevocation err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  padding: 6px;
  .box {
    width: 285px;
    height: 130px;
    // line-height: 130px;
    text-align: center;
    border: 1px solid #333333;
    box-sizing: border-box;
  }
  .input-wrap {
    padding-bottom: 6px;
    /deep/ .el-input {
      position: relative;
      &__inner {
        font-size: 16px;
        height: 45px;
        line-height: 45px;
        &::placeholder {
          // font-size: 18px;
          padding-left: 10px;
        }
      }
      &__suffix {
        top: 5px;
        right: 20px;
      }
    }
    // &:hover,
    // &:active {
    //   /deep/ .el-input {
    //     &__suffix {
    //       top: 0px;
    //       right: 20px;
    //     }
    //   }
    // }
    /deep/ .el-button {
      padding: 0 15px;
      height: 34px;
      .el-icon-search {
        font-size: 12px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .sheet-info {
    padding: 20px;
    padding-bottom: 5px;
    background: #fff;
    &__header {
      padding-bottom: 20px;
      text-align: center;
      font-size: 22px;
      color: $font-color;
    }
    &__body {
      font-size: 20px;
      .info-one {
        line-height: 35px;
        font-weight: 600;
      }
      .info-two {
        // display: flex;
        // gap: 60px;
        // line-height: 40px;
        display: flex;
        gap: 8px;
        padding: 10px 0 15px 0;
        /deep/ .el-select {
          width: 240px;
          height: 36px;
          &__tags {
            flex-wrap: nowrap;
            width: calc(100% - 100px) !important;
            max-width: 170px !important;
          }
          .el-input {
            width: 100%;
            height: 100%;
            &__inner {
              width: 100%;
              height: 100% !important;
            }
          }
        }
      }
    }
  }
  .list-wrap {
    display: flex;
    flex-direction: column;
    height: 580px;
    padding: 0 40px 20px 20px;
    line-height: 24px;
    background-color: #fff;
    color: rgba(#000000, 0.6);
    .box {
      padding: 17px;
      position: relative;
      text-align: left !important;
    }
  }
  .footer {
    margin-top: 6px;
    padding: 10px 0;
    background-color: #fff;
    text-align: center;
    &-btn {
      .el-button {
        font-size: 15px;
        &--warning {
          background-color: #fa884c;
          &:active {
            background-color: #ed7230;
          }
        }
      }
    }
    &-tip {
      padding-top: 10px;
      font-size: 14px;
      color: #fa884c;
    }
  }
}
$status_color: (
  todo: (
    background: #ffffff,
    border: $font-color,
    cursor: pointer,
  ),
  has: (
    background: #d6ffec,
    border: $theme-color,
    cursor: pointer,
  ),
  hasDo: (
    background: #fdd9d9,
    border: #fa884c,
    cursor: not-allowed,
  ),
  return: (
    background: #fff6da,
    border: #cdb684,
    cursor: not-allowed,
  ),
);
@each $key, $value in $status_color {
  .box.#{$key} {
    border: 1px solid map-get($value, "border") !important;
    background-color: map-get($value, "background");
    cursor: map-get($value, "cursor");
  }
}
.isHandle {
  cursor: pointer;
}
.unHandle,
.readonly {
  cursor: not-allowed;
}
</style>
