<template>
  <el-dialog
    title="复核"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="70%"
    class="confirm-count-wrap"
    center
  >
    <section class="body">
      <div class="body-header">
        <div class="title">点击确认即将发起退款，是否确认？</div>
      </div>
      <div class="body-content">
        <div class="table-wrap">
          <el-table
            height="100%"
            :data="tableData"
            :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
            border
          >
            <el-table-column
              label="自提点"
              prop="storeDeliveryName"
              minWidth="200"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="缺货数量"
              prop="outStockQuantity"
              minWidth="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="甩货数量"
              prop="throwQuantity"
              minWidth="100"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </section>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" @click="onHandleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "SubmitCountDialog",
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      // 供货单号
      supplyOrderNo: "",
    };
  },
  methods: {
    /**
     * 内容初始化
     */
    initShow({ list, supplyOrderNo }) {
      this.tableData = list;
      this.supplyOrderNo = supplyOrderNo;
      this.dialogVisible = true;
      // console.log("🆒 initShow", list);
    },
    /**
     * 确定数量
     */
    async onHandleSubmit() {
      this.$emit("on-submit-count", this.supplyOrderNo);
    },
    /**
     * 关闭
     */
    onHandleClose() {
      this.dialogVisible = false;
      this.tableData = [];
    },
  },
};
</script>

<style lang="scss" scoped>
$border: 1px solid #ebeef5;
.confirm-count-wrap {
  .body {
    &-header {
      .title {
        padding-bottom: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: $font-color;
      }
    }
    &-content {
      height: 575px;
      .table-wrap {
        height: 100%;
        /deep/ .el-table {
          &--border {
            border-right: $border;
          }
        }
      }
    }
  }
}
</style>
