<template>
  <el-dialog
    title="缺货原因"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        label-width="100px"
        ref="refForm"
        :model="formData"
        :rules="formRules"
      >
        <el-form-item label="缺货原因" prop="qcReasonDesc">
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="formData.qcReasonDesc"
            maxlength="100"
            show-word-limit
            clearable
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { validStrSpace } from "@/utils/tools/validate";

export default {
  name: "OutStockReasonDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      formData: {
        qcReasonDesc: "", // 缺货原因
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      formRules: {
        qcReasonDesc: [
          {
            required: true,
            // message: "输入缺货原因",
            validator(rule, value, callback) {
              if (!value) {
                callback(new Error("输入缺货原因!"));
              } else if (validStrSpace(value)) {
                callback(new Error("支持100个非空字符!"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      }, // 表单校验
      isAll: false, // 原因类型
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(bool) {
      this.isAll = bool || false;
      this.dialogVisible = true;
      this.submitLoading = false;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        this.$emit("on-edit-reason", this.formData.qcReasonDesc, this.isAll);
        this.onHandleClose();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
    .el-textarea {
      width: 550px;
    }
  }
}
</style>
