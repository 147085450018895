var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "confirm-count-wrap",
      attrs: {
        title: "复核",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "70%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c("section", { staticClass: "body" }, [
        _c("div", { staticClass: "body-header" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("点击确认即将发起退款，是否确认？"),
          ]),
        ]),
        _c("div", { staticClass: "body-content" }, [
          _c(
            "div",
            { staticClass: "table-wrap" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    height: "100%",
                    data: _vm.tableData,
                    "header-cell-style": {
                      color: "#333333",
                      background: "#EFF6FF",
                    },
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "自提点",
                      prop: "storeDeliveryName",
                      minWidth: "200",
                      "show-overflow-tooltip": "",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "缺货数量",
                      prop: "outStockQuantity",
                      minWidth: "100",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "甩货数量",
                      prop: "throwQuantity",
                      minWidth: "100",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleSubmit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }