var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page-wrap" },
    [
      _c(
        "div",
        { staticClass: "input-wrap" },
        [
          _c(
            "el-input",
            {
              ref: "refSheetOrder",
              attrs: {
                placeholder: "请在此输入或扫描供货单编码",
                autofocus: "true",
              },
              on: { change: _vm.changeSheetOrder },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.onHandleSearch.apply(null, arguments)
                },
              },
              model: {
                value: _vm.sheetOrder,
                callback: function ($$v) {
                  _vm.sheetOrder = $$v
                },
                expression: "sheetOrder",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "suffix",
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.onHandleSearch },
                  slot: "suffix",
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isShowInfo
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c("div", { staticClass: "sheet-info" }, [
                _c("div", { staticClass: "sheet-info__header" }, [
                  _vm._v(
                    " 供货单号：" + _vm._s(_vm.formData.supplyOrderNo) + " "
                  ),
                ]),
                _c("div", { staticClass: "sheet-info__body" }, [
                  _c("div", { staticClass: "info-one" }, [
                    _vm._v("供货日期：" + _vm._s(_vm.formData.supplyDate)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info-two" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "选择城市仓",
                            "filter-method": (val) =>
                              _vm.onFilterMethod("cityStore", val),
                            filterable: "",
                            clearable: "",
                          },
                          on: {
                            change: _vm.onChangeCityStore,
                            "visible-change": (val) =>
                              _vm.onChangeVisible("cityStore", val),
                          },
                          model: {
                            value: _vm.form.logisticsId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "logisticsId", $$v)
                            },
                            expression: "form.logisticsId",
                          },
                        },
                        _vm._l(_vm.cityStoreList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "选择自提点",
                            loading: _vm.selfTakeListLoading,
                            "filter-method": (val) =>
                              _vm.onFilterMethod("selfTake", val),
                            filterable: "",
                            clearable: "",
                          },
                          on: {
                            change: _vm.onChangeSelfTake,
                            "visible-change": (val) =>
                              _vm.onChangeVisible("selfTake", val),
                          },
                          model: {
                            value: _vm.form.deliveryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deliveryId", $$v)
                            },
                            expression: "form.deliveryId",
                          },
                        },
                        _vm._l(_vm.selfTakeList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "list-wrap" },
                [
                  this.tableData.length
                    ? _c(
                        "axle-pagination",
                        {
                          attrs: {
                            tableList: _vm.tableData,
                            unitClass: _vm.unitClass,
                          },
                        },
                        _vm._l(_vm.tableData, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class: [
                                _vm.unitClass,
                                Object.values(_vm.QC_STATUS_ENUM).find(
                                  (data) => data.value === item.qcStatus
                                ).key,
                                // Object.values(QC_HANDLE_ENUM).find(
                                //   (data) => data.value === item.isHandle
                                // ).key,
                                { readonly: _vm.isReadonly },
                              ],
                              attrs: { slot: "listBox" },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandleReview(item)
                                },
                              },
                              slot: "listBox",
                            },
                            [
                              _c("category", {
                                attrs: { index: index, data: item },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        [_c("c-empty-page", { attrs: { tip: "暂无数据~" } })],
                        1
                      ),
                ],
                1
              ),
              !_vm.isReadonly || (_vm.isReadonly && _vm.revocationTime)
                ? _c("div", { staticClass: "footer" }, [
                    !_vm.isReadonly
                      ? _c("div", { staticClass: "handle" }, [
                          _c(
                            "div",
                            { staticClass: "footer-btn" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.onHandleSubmit },
                                },
                                [_vm._v("复核确认")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "footer-tip" }, [
                            _vm._v(
                              " 点击复核完成将会给缺货的商品操作退款，请谨慎操作 "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.isReadonly && _vm.revocationTime
                      ? _c("div", { staticClass: "handle" }, [
                          _c(
                            "div",
                            { staticClass: "footer-btn" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "info", size: "mini" },
                                  on: { click: _vm.onHandleRevocation },
                                },
                                [_vm._v(_vm._s(`撤销 ${_vm.revocationTime}`))]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      !_vm.isShowInfo
        ? _c("c-empty-page", { attrs: { tip: "请扫描或输入供货单编号~" } })
        : _vm._e(),
      _c("confirm-count-dialog", {
        ref: "refConfirmCount",
        on: {
          "on-change-single": (data) => _vm.onHandleSearch(data),
          "on-show-reason": _vm.onShowReason,
        },
      }),
      _c("submit-all-dialog", {
        ref: "refSubmitAll",
        on: { "on-submit-count": _vm.onHandleAllReview },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }